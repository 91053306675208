import { createSlice } from '@reduxjs/toolkit';

export interface MobileState {
  notificationKey: string | null;
  deviceInfo: string | null;
}

const initialState: MobileState = {
  notificationKey: null,
  deviceInfo: 'Not set',
};

const { reducer, actions } = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setNotificationKey: (state, { payload }) => {
      state.notificationKey = payload;
    },

    setDeviceInfo: (state, { payload }) => {
      state.deviceInfo = payload;
    },
  },
});

export const { setNotificationKey, setDeviceInfo } = actions;
export default reducer;
