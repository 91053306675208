import React, { useMemo } from 'react';

import { ERole } from 'models/consts';

import { useAppSelector } from 'store/hooks';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';

import CustomerList from 'views/Agency/CustomerList';
import OrderList from 'views/Orders/OrderList';
import PreOrderList from 'views/PreOrders';
import PurchaseList from 'views/Purchase/PurchaseList';
import Impersonation from 'views/Settings/Impersonation';
import InfluencerLanding from '../views/Influencer/InfluencerLanding';

const OrderListRoute = () => {
  const role = useAppSelector(getMeRoleSelector);

  const Component = useMemo(() => {
    switch (role?.name) {
      case ERole.AGENCY_ADMIN:
      case ERole.RESOURCE_MANAGER:
        return <CustomerList />;
      case ERole.CUSTOMER_ADMIN:
      case ERole.CUSTOMER:
        return <PurchaseList />;
      case ERole.LEAD_MANAGER:
        return <PreOrderList />;
      case ERole.ADMIN:
        return <Impersonation />;
      case ERole.INFLUENCER:
        return <InfluencerLanding />;
      default:
        return <OrderList />;
    }
  }, [role?.name]);

  return Component;
};

export default OrderListRoute;
