import React, {
  FunctionComponent,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import isEqual from 'lodash/fp/isEqual';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getSpecialistSelector,
  getMeSelector,
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
} from 'store/selectors/getUserSelector';
import updateAction from 'store/actions/updateAction';

import { StyledStepContainer, StyledHeaderWrap } from '../../styled';

const ConsultantProfile = lazy(() => import('./Profile/ConsultantProfile'));
const ConsultantFullProfile = lazy(() => import('./Profile/ConsultantFullProfile'));
const ProductsExperienses = lazy(
  () => import('../../../components/ConsultantComponents/Products/ProductsExperienses'),
);
const ProductsExpertises = lazy(
  () => import('../../../components/ConsultantComponents/Products/ProductsExpertises'),
);

import Loader from 'components/Loader/Loader';

import ROUTES from 'router/constants';
import { EConsultantRegStep, ERole } from 'models/consts';
import { IBasicConsultant } from 'store/types/consultant';
import { StyledCustomScroll } from './styled';
import ConnectWithARep from './ConnectWithARep';
import { IBasicUser } from '../../../store/types/user';
import Button from '@mui/material/Button';
import logout from '../../../store/query/user/logout';
import { Capacitor } from '@capacitor/core';

const ConsultantRegister: FunctionComponent = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();

  const { step = 0 } = useParams();
  const navigate = useNavigate();
  const methods = useForm<any>({ mode: 'onChange' });

  const me = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getSpecialistSelector) as IBasicConsultant;
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);

  const {
    formState: { isValid, isSubmitting, errors },
    getValues,
  } = methods;

  const {
    actions,
    state: { experiences, expertise },
  } = useStateMachine({ updateAction });

  const [bypassIsValid, setBypassIsValid] = useState(false);

  const isSkip =
    bypassIsValid &&
    getValues().experiences &&
    getValues().experiences.length === 1 &&
    !getValues().experiences[0].product;

  useEffect(() => {
    if (consultant?.agencyId) {
      actions.updateAction({ experiences: [], expertise: [], worker: {} });
    }
  }, [actions, consultant?.agencyId]);

  const submit = useCallback(() => {
    navigate(`${ROUTES.CONSULTANT_REGISTRATION}/${+step + 1}`);
  }, [navigate, step]);

  const goHome = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  const skip = useCallback(() => {
    if (consultant?.agencyId) {
      navigate(ROUTES.CONSULTANT, {
        state: {
          enableCongratsModal: false,
        },
      });
    } else {
      navigate(`${ROUTES.CONSULTANT_REGISTRATION}/4`);
    }
  }, [consultant?.agencyId, navigate]);

  const onLogout = useCallback(() => {
    dispatch(logout())
      .unwrap()
      .then(() => navigate(ROUTES.AUTH));
  }, [dispatch, navigate]);

  useEffect(() => {
    (isAgencyAdmin || isResourceManager) && navigate(ROUTES.ORDER_LIST);
  }, [navigate, isAgencyAdmin, isResourceManager]);

  const renderStep = useCallback(() => {
    switch (+step) {
      case EConsultantRegStep.PROFILE:
        return !!consultant?.agencyId ? (
          <ConsultantFullProfile isModal={false} onSubmitted={submit} />
        ) : (
          <ConsultantProfile onSubmitted={submit} />
        );
      case EConsultantRegStep.PRODUCTS:
        return (
          <ProductsExperienses
            onSubmitted={submit}
            bypassIsValid={bypassIsValid}
            setBypassIsValid={setBypassIsValid}
          />
        );
      case EConsultantRegStep.PROFICIENCY:
        return (
          <ProductsExpertises onSubmitted={me.role.name !== ERole.INFLUENCER ? submit : goHome} />
        );
      default:
        return <ConnectWithARep />;
    }
  }, [bypassIsValid, consultant?.agencyId, me.role, step, submit, goHome]);

  const nextBtn = useCallback(
    () => (
      <LoadingButton
        data-test="next-button"
        loading={isSubmitting}
        form="hook-form"
        type="submit"
        sx={{ backgroundColor: mobile ? 'black' : undefined, color: mobile ? 'white' : undefined }}
        disabled={
          !isValid ||
          (errors.nickName && !!errors.nickName.message) ||
          false ||
          (+step === EConsultantRegStep.PRODUCTS &&
            !isEqual(experiences, getValues().experiences || null)) ||
          (+step === EConsultantRegStep.PROFICIENCY &&
            !isEqual(expertise, getValues().expertise || null))
        }
        variant="contained"
      >
        {+step === EConsultantRegStep.CONNECT ||
        (+step === EConsultantRegStep.PROFICIENCY &&
          (!!consultant?.agencyId || me.role.name === ERole.INFLUENCER))
          ? 'Submit'
          : 'Next Step'}
      </LoadingButton>
    ),
    [
      isValid,
      isSubmitting,
      step,
      experiences,
      expertise,
      errors.nickName,
      mobile,
      getValues,
      consultant?.agencyId,
      me,
    ],
  );

  const skipButton = useMemo(
    () => (
      <LoadingButton
        id="skip-button"
        onClick={skip}
        sx={{ backgroundColor: mobile ? 'black' : undefined, color: mobile ? 'white' : undefined }}
        variant="contained"
      >
        Skip
      </LoadingButton>
    ),
    [mobile, skip],
  );

  const cancelButton = useMemo(
    () => (
      <Button
        id="cancel-button"
        onClick={onLogout}
        sx={{ backgroundColor: mobile ? 'black' : undefined, color: mobile ? 'white' : undefined }}
        variant="contained"
      >
        Cancel
      </Button>
    ),
    [mobile, onLogout],
  );

  const headerTitle = useMemo(() => {
    switch (+step) {
      case EConsultantRegStep.PRODUCTS:
        return {
          title: 'Share your work and project history to determine your seniority and hourly rate',
          subTitle:
            'Your work history impacts your pay and appeals to clients. This helps us match you with suitable opportunities and ensure fair compensation. Be specific, including:',
        };
      case EConsultantRegStep.PROFICIENCY:
        return {
          title: 'Select your preferred modules and expertise level',
          subTitle:
            'To match you with relevant projects, please indicate your interest and proficiency in each module. By clearly communicating your preferences and expertise, we can better align you with projects that suit your skills and interests.',
        };
      default:
        return {
          title: 'Tell us about youself',
          subTitle: !!consultant?.agencyId
            ? `You are joining as a part of the ${consultant?.agency?.name} agency.`
            : `As part of our verification process, we'll ask you to share some basic information. This helps us validate your identity and ensure the safety of our platform.`,
        };
    }
  }, [step, consultant?.agencyId, consultant?.agency?.name]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: {
          xs: `85% / cover no-repeat ${+step >= EConsultantRegStep.CONNECT ? '#FFCA28' : undefined}`,
          md: `center / cover no-repeat ${+step >= EConsultantRegStep.CONNECT ? '#FFCA28' : undefined}`,
        },
        backgroundImage: {
          xs: +step >= EConsultantRegStep.CONNECT ? 'url(/img/workerbee-video.png)' : undefined,
          md: +step >= EConsultantRegStep.CONNECT ? 'url(/img/workerbee-video.png)' : undefined,
        },
      }}
    >
      <Container maxWidth="xxl">
        <StyledHeaderWrap>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid xs justifyContent="space-between" display="flex">
              {+step < EConsultantRegStep.CONNECT && (
                <>
                  <Box sx={{ minHeight: '85px', maxWidth: '76%' }}>
                    <Typography
                      data-test={`consultant-register-header-${step}`}
                      variant="h1"
                      sx={{ mb: { xs: 2, sm: 0, lg: 2.5 } }}
                    >
                      {headerTitle.title}
                    </Typography>
                    <Typography component="p" mb={2} sx={{ fontSize: '1.125rem' }}>
                      {headerTitle.subTitle}
                    </Typography>
                  </Box>
                  <Box display={{ xs: 'none', md: 'flex' }}>{isSkip ? skipButton : nextBtn()}</Box>
                </>
              )}
            </Grid>
          </Grid>
        </StyledHeaderWrap>
      </Container>
      <StyledCustomScroll allowOuterScroll flex="10">
        <Container maxWidth="xxl" sx={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
          <StyledStepContainer>
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid xs>
                <FormProvider {...methods}>
                  <Suspense fallback={<Loader />}>{(me && renderStep()) || <Loader />}</Suspense>
                </FormProvider>
                {+step < EConsultantRegStep.CONNECT && (
                  <Box mb={6} mt={5} display={{ xs: 'flex', md: 'none' }} justifyContent="center">
                    {isSkip ? skipButton : nextBtn()}
                  </Box>
                )}
                {Capacitor.isNativePlatform() && (
                  <Box mb={6} mt={2} display={{ xs: 'flex', md: 'none' }} justifyContent="center">
                    {cancelButton}
                  </Box>
                )}
              </Grid>
            </Grid>
          </StyledStepContainer>
        </Container>
      </StyledCustomScroll>
    </Box>
  );
};

export default ConsultantRegister;
