const config = {
    REACT_APP_API_URL: 'https://api.myworkerbee.com',
    REACT_APP_ADMIN_URL: '@REACT_APP_ADMIN_URL',
    REACT_APP_STRIPE_PUBLIC_KEY: '@REACT_APP_STRIPE_PUBLIC_KEY',
    REACT_APP_GOOGLE_API_KEY: 'AIzaSyDmdXIW5_XH3FnSr0j3yRyXx5MOLfkND9E',
    REACT_APP_GA_GTM: 'GTM-KLHG492',
    REACT_APP_GTM_AUTH: '7utJUBjxpKMRG0KfqwWDoQ',
    REACT_APP_GTM_PREVIEW: 'env-1',
    SENTRY_DSN: 'Production',
    SENTRY_ENV: '',
    PRODUCT_COMPANY_ID: '',
    QUICK_AGENCY_ID: '',
    CALENDLY_URL: 'https://calendly.com/workerbee-onboarding/welcome-on-workerbee',
  };

  export default config;