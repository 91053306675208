import React, { FunctionComponent } from 'react';
import { RefCallBack } from 'react-hook-form';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Capacitor } from '@capacitor/core';

import config from '../../../app.config';

import { ArrowDropDownIcon } from 'styles/icons/ArrowDropDown';
import { ArrowDropUpIcon } from 'styles/icons/ArrowDropUp';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import BootstrapInput from '../BootstrapInput';
import { isLocal } from '../../../helpers/environmentDetector';

interface ILocationInput {
  inputRef?: RefCallBack;
  dataTest?: string;
  onChange: (value: any) => void;
  value: any;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  trigger?: (name?: string | string[]) => void;
  name: string;
  autocompletionRequest?: any;
  disabled?: boolean;
}

const styles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
    outline: 'none',
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
    minHeight: '48px',
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '1.125rem',
    color: '#1f1f1f',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '0.875rem',
    color: '#5D5D5E',
    opacity: 1,
    textAlign: 'start',
    marginLeft: 5,
  }),
  menu: (provided: any) => ({
    ...provided,
    border: 'none',
    borderRadius: 0,
    margin: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '4px 0',
    fontSize: '1rem',
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    padding: '6px 16px',
    textAlign: 'left',
  }),
  option: (provided: any, { isSelected }: any) => ({
    ...provided,
    padding: '6px 16px',
    textAlign: 'left',
    backgroundColor: isSelected && 'transparent',
    fontWeight: (isSelected && 700) || 400,
    color: '#1F1F1F',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: !isSelected && 'rgba(0, 0, 0, 0.04)',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    padding: '0 4px',
    fontSize: '1.125rem',
  }),
};

const LocationInput: FunctionComponent<ILocationInput> = ({
  inputRef,
  onChange,
  value,
  placeholder = 'Start typing your location (e.g., Las Vegas, NV)...',
  dataTest = 'google-places-autocomplete',
  error,
  errorText,
  autocompletionRequest,
  disabled,
}) => {
  const defaultAutocompletionRequest = {
    types: ['(cities)'],
  };
  const isMobileNative = Capacitor.isNativePlatform();

  return (
    <FormControl error={error} fullWidth={true}>
      <GooglePlacesAutocomplete
        apiKey={config.REACT_APP_GOOGLE_API_KEY}
        selectProps={{
          placeholder: placeholder,
          value: (value?.label && value) || undefined,
          onChange: onChange,
          styles: styles,
          defaultOptions: isLocal()
            ? [{ value: 'Sample City, Nowhere', label: 'Sample City, NO GOOGLE API KEY' }]
            : [],
          components: {
            DropdownIndicator: ({ isFocused }: any) =>
              (isFocused && <ArrowDropUpIcon />) || <ArrowDropDownIcon />,
            IndicatorSeparator: () => null,
            ...(!isMobileNative
              ? {
                  Input: ({ onBlur, onChange, onFocus, type, value }: any) => (
                    <BootstrapInput
                      id="input-location"
                      name="input-location"
                      onBlur={onBlur}
                      onChange={onChange}
                      onFocus={onFocus}
                      type={type}
                      value={value}
                      inputRef={inputRef}
                      dataTest={dataTest}
                      sx={{ background: 'transparent', input: { px: 0, ml: '4px' } }}
                      disabled={disabled}
                      formControlSx={{ display: 'inline-grid', gridArea: '1 / 1 / 2 / 3' }}
                    />
                  ),
                  SingleValue: option => (
                    <div
                      data-test={`${dataTest}-value-block`}
                      style={{ ...(option.getStyles('singleValue', option) as any) }}
                    >
                      {option.data.label}
                    </div>
                  ),
                }
              : {}),
          },
        }}
        autocompletionRequest={autocompletionRequest || defaultAutocompletionRequest}
      />
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default LocationInput;
