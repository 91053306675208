import { createAsyncThunk } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { Capacitor } from '@capacitor/core';

import instance from '../../middlewares/api';
import { EUser } from '../../constants';
import { ELocalStoreKeys, ERole, mobileCookieMaxAge } from 'models/consts';
import fetchMe from './fetchMe';
import { removeAuthTokens, removeImpersonateTokens, saveTokens } from 'utils/apiCallHelpers';

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });
const isMobileNative = Capacitor.isNativePlatform();

interface ISignupProps {
  email: string;
  password: string;
  code?: string;
  role: ERole;
  wbSource?: string;
}

const signup = createAsyncThunk<any, ISignupProps>(EUser.signup, async (payload, thunkAPI) => {
  let response;

  if (
    payload.code &&
    [ERole.CONSULTANT, ERole.LEAD_MANAGER, ERole.RESOURCE_MANAGER].includes(payload.role)
  ) {
    const { email, role, ...data } = payload;
    response = await instance.post('agency/users-team/registration-user', data);
  } else if (payload.code && [ERole.INFLUENCER].includes(payload.role)) {
    const { email, role, ...data } = payload;
    response = await instance.post('influencers/registration', data);
  } else {
    response = await instance.post('auth/registration', payload);
  }

  const {
    data: { accessToken, refreshToken, ...data },
  } = response;

  await removeAuthTokens();
  await removeImpersonateTokens();

  saveTokens(accessToken, refreshToken);

  await thunkAPI.dispatch(fetchMe());

  return data;
});

export default signup;
