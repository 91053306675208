import React, { lazy, Suspense } from 'react';
import { redirect, useParams } from 'react-router-dom';
import { Cookies } from 'react-cookie';

import Box from '@mui/material/Box';

import ROUTES from './router/constants';

import { ERole, ORDER_URL } from './models/consts';

import Loader from './components/Loader/Loader';
import AuthLayout from './layouts/AuthLayout';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import FixWidthLayout from './layouts/AuthorizedLayout/FixWidthLayout';
import FullwidthLayout from './layouts/AuthorizedLayout/FullwidthLayout';
import DocumentLayout from './layouts/DocumentLayout';
import FullscreenLayout from './layouts/FullscreenLayout';

const PaymentLayout = lazy(() => import('./layouts/PaymentLayout'));

// Auth routes
const Signin = lazy(() => import('./views/Auth/Signin'));
const Signup = lazy(() => import('./views/Auth/Signup'));
const SignupCustomer = lazy(() => import('./views/Auth/SignupCustomer'));
const ForgotPassword = lazy(() => import('./views/Auth/ForgotPassword'));
const VerificationCode = lazy(() => import('./views/Auth/VerificationCode/VerificationCode'));
const ResetPassword = lazy(() => import('./views/Auth/ResetPassword'));
const CustomerProfileRegistration = lazy(() => import('./views/Auth/CustomerProfileRegistration'));
const EnterpriseCustomerRegistration = lazy(
  () => import('./views/Auth/EnterpriseCustomerRegistration'),
);
// const AgencyRegistration = lazy(() => import('./views/Auth/AgencyRegistration'))

// Consultant routes
import ConsultantRegister from 'views/Auth/ConsultantRegister';
import ConsultantOrderCreation from './views/ConsultantOrderCreation';

import Consultant from 'views/Consultant/Consultant';
// const Consultant = lazy(() => import('./views/Consultant/Consultant'))

// Customer routes
import OrderFlow from './views/OrderFlow';

// Order routes
const OrderList = lazy(() => import('./views/Orders/OrderList'));
const OrderPage = lazy(() => import('./views/Orders/OrderPage'));
const DraftOrderPage = lazy(() => import('./views/Orders/OrderPage/DraftOrderPage'));
const PreOrderPage = lazy(() => import('./views/PreOrders/OrderPage'));
const PreOrderList = lazy(() => import('./views/PreOrders/PreOrderList'));
const PurchaseOrdersList = lazy(() => import('./views/Purchase/PurchaseOrdersList'));

const PaymentPage = lazy(() => import('./views/PaymentPage'));
// Profile routes
const PaymentDetails = lazy(() => import('./views/Settings/PaymentDetails'));
const Security = lazy(() => import('./views/Settings/Security'));

import Calendar from './views/Calendar';

// Chat Routes
import ChatPage from 'views/ChatPage';
const Chat = lazy(() => import('./views/ChatPage/Chat'));

// Ai Chat
const ChatAssistantPage = lazy(() => import('./views/ChatAssistantPage'));

// Docs
const TermsOfUse = lazy(() => import('./views/TextPages/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('./views/TextPages/PrivacyPolicy'));
const EmailUnsubscribe = lazy(() => import('./views/TextPages/EmailUnsubscribe'));
const ConsultantValidateAvailability = lazy(
  () => import('./views/TextPages/ConsultantValidateAvailability'),
);
const CustomerUserAgreement = lazy(() => import('./views/TextPages/CustomerUserAgreement'));
// Agency
import AgencyCreationFlow from 'views/AgencyCreationFlow';

const ProductAndExperience = lazy(() => import('./views/ProductAndExperience'));
// Enterprises

const PurchaseListManage = lazy(() => import('./views/Purchase/PurchaseListManage'));

const AgencyRates = lazy(() => import('./views/AgencyRates'));

const LoginJwt = lazy(() => import('./views/LoginJwt'));

import ReviewPreorder from './views/ReviewPreorder';

// import OrderListRoute from "./router/OrderListRoute";

import OrderListRoute from './router/OrderListRoute';
import TeamListRoute from './router/TeamListRoute';
import ContactDetailsRoute from './router/ContactDetailsRoute';

import AgencyProfile from 'views/Agency/AgencyProfile';

import App from './App';

import ErrorBoundary from './views/ErrorBoundary';

import instance, { setupResponseInterceptor } from './store/middlewares/api';
import store from './store/store';
import setUserFromSSR from './store/query/user/setUserFromSSR';
import setConsultantProductsFull from './store/query/consultant/setConsultantProductsFull';
import Impersonation from 'views/Settings/Impersonation';
import Page404 from './views/404';
import ValidateRoute from './views/ValidateRoute';

setupResponseInterceptor(store);

const checkInviteLoader = async ({ params }: any) => {
  if (params.hash) {
    const { data } = await instance.get(`invite-links/${params.hash}`);

    return data;
  }
  return { company: null, metaData: null, email: null, role: null };
};

const checkPaymentHash = async ({ params }: any) => {
  if (params.hashCode) {
    await instance.get(`/shared-links/${params.hashCode}`);
  }
  return null;
};

const routes = [
  {
    id: 'root',
    errorElement: <ErrorBoundary />,
    element: <App />,
    loader: async ({ request, params, ...rest }: any) => {
      const cookieHeaderValue = new Cookies(request.headers.get('cookie'), { path: '/' });

      if (
        (cookieHeaderValue.get('accessToken') || cookieHeaderValue.get('impersonateToken')) &&
        !params.hash &&
        !/login-jwt/.test(request.url)
      ) {
        try {
          const { data } = await instance.get(`/auth/me`, {
            headers: {
              Authorization: `Bearer ${cookieHeaderValue.get('impersonateToken') || cookieHeaderValue.get('accessToken')}`,
              'Refresh-Auth':
                cookieHeaderValue.get('impersonateRefreshToken') ||
                cookieHeaderValue.get('refreshToken'),
            },
          });
          store.dispatch<any>(setUserFromSSR(data));
          if ([ERole.CONSULTANT, ERole.INFLUENCER].includes(data.role.name)) {
            const { data: experiences }: any = await instance.get(
              `/consultant-products/${data.id}/full?limit=100`,
              {
                headers: {
                  Authorization: `Bearer ${cookieHeaderValue.get('impersonateToken') || cookieHeaderValue.get('accessToken')}`,
                  'Refresh-Auth':
                    cookieHeaderValue.get('impersonateRefreshToken') ||
                    cookieHeaderValue.get('refreshToken'),
                },
              },
            );
            store.dispatch<any>(setConsultantProductsFull({ data: experiences, userId: data.id }));
          }

          return data;
        } catch (e: any) {
          return new Response(null, { status: e.statusCode });
        }
      }
      return null;
    },
    children: [
      {
        path: ROUTES.AUTH,
        element: <AuthLayout />,
        children: [
          {
            path: ROUTES.CUSTOMER_REGISTRATION,
            element: (
              <Suspense fallback={<Loader />}>
                <CustomerProfileRegistration />
              </Suspense>
            ),
          },
          {
            index: true,
            element: (
              <Suspense fallback={<Loader />}>
                <Signin />
              </Suspense>
            ),
          },
          {
            path: `${ROUTES.SIGN_UP}/:hash?`,
            element: (
              <Suspense fallback={<Loader />}>
                <Signup />
              </Suspense>
            ),
            loader: checkInviteLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: ROUTES.SIGN_UP_CUSTOMER,
            element: (
              <Suspense fallback={<Loader />}>
                <Signup />
              </Suspense>
            ),
            loader: checkInviteLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: `${ROUTES.SIGN_UP_CUSTOMER}/:hash`,
            element: (
              <Suspense fallback={<Loader />}>
                <SignupCustomer userRole={ERole.CUSTOMER} />
              </Suspense>
            ),
            loader: checkInviteLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: `${ROUTES.SIGN_UP_AGENCY}/:hash`,
            element: (
              <Suspense fallback={<Loader />}>
                <Signup userRole={ERole.AGENCY_ADMIN} />
              </Suspense>
            ),
            loader: checkInviteLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: ROUTES.CODE,
            element: (
              <Suspense fallback={<Loader />}>
                <VerificationCode />
              </Suspense>
            ),
          },
          {
            path: ROUTES.SIGNUP_CODE,
            element: (
              <Suspense fallback={<Loader />}>
                <VerificationCode isRegistration />
              </Suspense>
            ),
          },
          {
            path: ROUTES.FORGOT_PASSWORD,
            element: (
              <Suspense fallback={<Loader />}>
                <ForgotPassword />
              </Suspense>
            ),
          },
          {
            path: ROUTES.RESET_PASSWORD,
            element: (
              <Suspense fallback={<Loader />}>
                <ResetPassword />
              </Suspense>
            ),
          },
          {
            path: `${ROUTES.SIGN_UP_ENTERPRISE_CUSTOMER}/:hash`,
            element: (
              <Suspense fallback={<Loader />}>
                <EnterpriseCustomerRegistration />
              </Suspense>
            ),
            loader: checkInviteLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: '*',
            loader: () => redirect(ROUTES.AUTH),
          },
        ],
      },
      {
        element: <FullscreenLayout />,
        children: [
          {
            path: `${ROUTES.ORDER_CREATION}/${ROUTES.STEP}`,
            element: <ValidateRoute component={OrderFlow} matchRegExp="^[1-5]$" />,
          },
          {
            path: `${ROUTES.CONSULTANT_REGISTRATION}/${ROUTES.STEP}`,
            element: <ValidateRoute component={ConsultantRegister} matchRegExp="^[1-4]$" />,
          },
          {
            path: `${ROUTES.AGENCY_CREATION}/${ROUTES.STEP}`,
            element: <ValidateRoute component={AgencyCreationFlow} matchRegExp="^[1]$" />,
          },
        ],
      },
      {
        element: <AuthorizedLayout />,
        children: [
          {
            element: <FixWidthLayout />,
            children: [
              {
                path: ROUTES.ORDER_LIST,
                element: <OrderListRoute />,
              },
              {
                path: ROUTES.TEAM,
                element: <TeamListRoute />,
              },
              {
                path: ROUTES.CONTACT_DETAILS,
                element: <ContactDetailsRoute />,
              },
              {
                path: ROUTES.AGENCY_PROFILE,
                element: <AgencyProfile />,
              },
              {
                path: `${ROUTES.ORDER_FORM_LIST}/:id`,
                element: (
                  <Suspense fallback={<Loader />}>
                    <PurchaseOrdersList />
                  </Suspense>
                ),
              },
              {
                path: `${ROUTES.AGENCY_CUSTOMER_ORDER}/:id`,
                element: (
                  <Suspense fallback={<Loader />}>
                    <OrderList />
                  </Suspense>
                ),
              },
              {
                path: `${ROUTES.AGENCY_PREORDER}/:statuses`,
                element: (
                  <Suspense fallback={<Loader />}>
                    <PreOrderList />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.PRODUCT_AND_EXPERIENCE,
                element: (
                  <Suspense fallback={<Loader />}>
                    <ProductAndExperience />
                  </Suspense>
                ),
              },
              {
                path: `${ROUTES.ORDER}/:id/:orderType?`,
                element: (
                  <Suspense fallback={<Loader />}>
                    <OrderPage />
                  </Suspense>
                ),
              },
              {
                id: 'consultant',
                path: `${ROUTES.CONSULTANT}/:id?`,
                element: <Consultant />,
                loader: async ({ params, request }: any) => {
                  if (params.id) {
                    const cookieHeaderValue = new Cookies(request.headers.get('cookie'), {
                      path: '/',
                    });
                    try {
                      const { data } = await instance.get(`consultant/${params.id}`, {
                        headers: {
                          ...(cookieHeaderValue.get('impersonateToken') ||
                            (cookieHeaderValue.get('accessToken') && {
                              Authorization: `Bearer ${cookieHeaderValue.get('impersonateToken') || cookieHeaderValue.get('accessToken')}`,
                              'Refresh-Auth':
                                cookieHeaderValue.get('impersonateRefreshToken') ||
                                cookieHeaderValue.get('refreshToken'),
                            })),
                        },
                      });
                      return data;
                    } catch (e) {
                      console.error(e);
                      throw new Response('Not Found', { status: 404 });
                    }
                  }
                  return null;
                },
                errorElement: <Page404 />,
              },
              {
                id: 'sharable-specialist',
                path: `${ROUTES.SHARABLE_CONSULTANT}/:hashCode`,
                element: <Consultant />,
                loader: async ({ params }: any) => {
                  if (params.hashCode) {
                    try {
                      const { data } = await instance.get(`consultant/shared/${params.hashCode}`);
                      return data;
                    } catch (e) {
                      console.error(e);
                      throw new Response('Not Found', { status: 404 });
                    }
                  }
                  return null;
                },
                errorElement: <Page404 />,
              },
              {
                path: ROUTES.PAYMENT_DETAILS,
                element: (
                  <Suspense fallback={<Loader />}>
                    <PaymentDetails />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.SECURITY,
                element: (
                  <Suspense fallback={<Loader />}>
                    <Security />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.IMPERSONATION,
                element: (
                  <Suspense fallback={<Loader />}>
                    <Impersonation />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.CHAT,
                element: <ChatPage />,
                children: [
                  {
                    path: ROUTES.CHAT_ROOM,
                    element: (
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'stretch',
                              flex: '1 1 100%',
                              flexDirection: 'column',
                            }}
                          >
                            <Loader />
                          </Box>
                        }
                      >
                        <Chat />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: ROUTES.CHAT_ASSISTANT,
                element: (
                  <Suspense fallback={<Loader />}>
                    <ChatAssistantPage />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.ORDER_FORMS,
                element: (
                  <Suspense fallback={<Loader />}>
                    <PurchaseListManage />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.CONSULTANT_ORDER_CREATION,
                element: <ConsultantOrderCreation />,
              },
              {
                path: `${ROUTES.DRAFT_ORDER}/:id`,
                element: (
                  <Suspense fallback={<Loader />}>
                    <DraftOrderPage />
                  </Suspense>
                ),
              },
              {
                path: `${ROUTES.PRE_ORDER}/:id`,
                element: (
                  <Suspense fallback={<Loader />}>
                    <PreOrderPage />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.PARTNER_RATES,
                element: (
                  <Suspense fallback={<Loader />}>
                    <AgencyRates />
                  </Suspense>
                ),
              },
              {
                path: ROUTES.EDIT_PREORDER,
                element: <ReviewPreorder />,
              },
            ],
          },
          {
            element: <FullwidthLayout />,
            children: [
              {
                path: ROUTES.CALENDAR,
                element: <Calendar />,
              },
              {
                path: '*',
                element: <Page404 />,
              },
            ],
          },
        ],
      },
      {
        element: <PaymentLayout />,
        children: [
          {
            path: `${ROUTES.PAYMENT}/:hashCode`,
            element: (
              <Suspense fallback={<Loader />}>
                <PaymentPage />
              </Suspense>
            ),
            loader: checkPaymentHash,
          },
        ],
      },
      {
        element: <DocumentLayout />,
        children: [
          {
            path: ROUTES.TERMS_OF_USE,
            element: (
              <Suspense fallback={<Loader />}>
                <TermsOfUse />
              </Suspense>
            ),
          },
          {
            path: ROUTES.PRIVACY_POLICY,
            element: (
              <Suspense fallback={<Loader />}>
                <PrivacyPolicy />
              </Suspense>
            ),
          },
          {
            path: ROUTES.CUSTOMER_USER_AGREEMENT,
            element: (
              <Suspense fallback={<Loader />}>
                <CustomerUserAgreement />
              </Suspense>
            ),
          },
          {
            path: ROUTES.EMAIL_UNSUBSCRIBE,
            element: (
              <Suspense fallback={<Loader />}>
                <EmailUnsubscribe />
              </Suspense>
            ),
          },
          {
            path: ROUTES.CONSULTANT_VALIDATE_AVAILABILITY,
            element: (
              <Suspense fallback={<Loader />}>
                <ConsultantValidateAvailability />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: ROUTES.LOGIN_JWT,
        element: (
          <Suspense fallback={<Loader />}>
            <LoginJwt />
          </Suspense>
        ),
      },
    ],
  },
];

export default routes;
