import React, { FC, SyntheticEvent, useEffect, useRef } from 'react';
import { Controller, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import dayjs from 'utils/dayjs';
import DatePicker from 'react-datepicker';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IExperienceBasis } from 'models/inner-models';
import { customFilter } from 'helpers/customFilter';
import { ICompany } from 'store/types/company';

import { useAppDispatch } from 'store/hooks';
import useSearchCompanyByName from 'hooks/useSearchCompanyByName';
import { createConsultantCompany } from 'store/query/consultant';
import saveCompanyLogo from 'store/query/company/saveCompanyLogo';

import CalendarIcon from 'styles/icons/CalendarIcon';
import CameraIcon from 'styles/icons/CameraIcon';
import CloseIcon from 'styles/icons/CloseIcon';

import {
  StyledCustomSwitch,
  StyledFormGroup,
  StyledInputLabel,
} from 'components/CustomFields/styled';
import { StyledCloseButton } from '../styled';

import BootstrapInput from '../../../CustomFields/BootstrapInput';
import CustomAutocomplete from '../../../CustomFields/CustomAutocomplete';
import DateTextInput from '../../../CustomFields/DateTextInput';
import UploadPhoto from '../../../CustomFields/UploadPhoto';
import { Step } from 'react-joyride';

interface IExperiencesItem {
  disabledForAgency: boolean;
  item: IExperienceBasis;
  name: string;
  onRemoveExperiences: () => void;
  onSaveExperiences: () => void;
  setSteps?: React.Dispatch<React.SetStateAction<Step[]>>;
  setRun?: React.Dispatch<React.SetStateAction<boolean>>;
  showIntro?: boolean;
}

const switchValue = {
  project: false,
  role: true,
};

type ISwitchValue = {
  project: false;
  role: true;
};

const ExperienceForm: FC<IExperiencesItem> = ({
  disabledForAgency,
  name,
  item,
  onRemoveExperiences,
  onSaveExperiences,
  setSteps,
  setRun,
  showIntro,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const itemTemp = useRef(item);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    companies,
    setSearchTerm,
    isLoading: isCompaniesLoading,
    setLoading,
  } = useSearchCompanyByName({});

  const { control, formState, getFieldState, setValue, trigger, watch, getValues } =
    useFormContext<FieldValues>();

  const { isDirty } = getFieldState(name, formState);
  const fieldState = getFieldState(name);

  const watchStartDate = useWatch({
    control,
    name: `${name}.startDate`,
  });

  const watchEndDate = useWatch({
    control,
    name: `${name}.endDate`,
  });

  const watchCompany = useWatch({
    control,
    name: `${name}.company`,
  });

  const handleCreateCompany = (company: ICompany) => {
    if (company.create) {
      dispatch(
        createConsultantCompany({
          company: {
            name: company.name,
            roleId: 4,
          },
        }),
      )
        .unwrap()
        .then(data => {
          setValue(`${name}.company`, {
            ...data[0],
            create: `Add new company: "${company.name}"`,
          });
          setValue(`${name}.companyId`, data[0].id);
        });
    }
    setValue(`${name}.companyId`, company.id);
  };

  const handleCompanySearch = (event: SyntheticEvent, value: string) => {
    if (value !== ' ') {
      setLoading(true);
      setSearchTerm(value);
    }
  };

  const handelUploadPhoto = (blob: Blob) => {
    if (watchCompany) {
      dispatch(
        saveCompanyLogo({
          companyId: watchCompany.id,
          logo: blob,
        }),
      )
        .unwrap()
        .then(({ link }) => setValue(`${name}.company.logo`, link));
    }
  };

  useEffect(() => {
    if (showIntro) {
      const timeoutId: any = setTimeout(() => {
        let stepsList: Step[] = [];
        if (
          !itemTemp.current.activities ||
          !itemTemp.current.companyId ||
          !itemTemp.current.description ||
          !itemTemp.current.startDate
        ) {
          stepsList.push({
            target: `.work-history-input`,
            title: 'Work History',
            content:
              'Include a brief history of relevant jobs or projects. This demonstrates your experience level and success in your field.',
            placement: 'top',
            disableBeacon: true,
          });
        }

        setSteps && setSteps(stepsList);

        if (stepsList.length) {
          stepsList.push({
            target: '.save-button',
            title: 'Save',
            content: 'Click Save button to save your changes.',
            placement: mobile ? 'top' : 'right',
            disableBeacon: true,
          });
          setRun && setRun(true);
        }
      }, 700);
      return () => clearTimeout(timeoutId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showIntro,
    itemTemp.current.activities,
    itemTemp.current.companyId,
    itemTemp.current.description,
    itemTemp.current.startDate,
  ]);

  return (
    <>
      <Grid container spacing={2} mt={1} className="work-history-input">
        <Grid xs={12} lg="auto" order={1}>
          <FormGroup>
            <FormControl variant="filled">
              <Controller
                control={control}
                name={`${name}.activities`}
                render={({ field: { ref, ...field } }) => (
                  <FormControlLabel
                    {...field}
                    control={
                      <StyledCustomSwitch
                        checked={switchValue[field.value as keyof ISwitchValue] || false}
                        onChange={e => field.onChange(e.target.checked ? 'role' : 'project')}
                        sx={{ width: '100%' }}
                      />
                    }
                    label={<></>}
                    sx={{ m: 0 }}
                  />
                )}
              />
            </FormControl>
          </FormGroup>
          {!mobile && (
            <FormGroup sx={{ pt: 2 }}>
              <Controller
                control={control}
                defaultValue={item?.isShowCompanyLogo}
                name={`${name}.isShowCompanyLogo`}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-test="intro-checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          field.onChange(event.target.checked);
                        }}
                        size="small"
                        checkedIcon={<span />}
                        icon={<span />}
                      />
                    }
                    label="Show in my Intro"
                    sx={{ marginLeft: 0, alignItems: 'flex-start' }}
                  />
                )}
              />
            </FormGroup>
          )}
        </Grid>
        <Grid container lg xs={12} md={8} order={3}>
          <Grid xs={12}>
            <FormGroup>
              <Controller
                name={`${name}.company`}
                control={control}
                defaultValue={item?.company}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      disabled: disabledForAgency,
                      multiple: undefined,
                      onInputChange: handleCompanySearch,
                    }}
                    inputProps={{
                      dataTest: 'company',
                      error: !!(fieldState.error as any)?.company,
                      helperText: (fieldState.error as any)?.company?.message || 'Incorrect data',
                    }}
                    field={field}
                    options={companies || []}
                    placeholder="Type a company name"
                    customFilter={customFilter}
                    handleChange={handleCreateCompany}
                    isLoading={isCompaniesLoading}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid xs={12}>
            <FormGroup>
              <FormControl variant="filled">
                <Controller
                  control={control}
                  name={`${name}.description`}
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      {...field}
                      dataTest="job-title-input"
                      inputRef={ref}
                      error={!!(fieldState.error as any)?.description}
                      helperText={
                        (fieldState.error as any)?.description?.message || 'Incorrect data'
                      }
                      placeholder="Type job title"
                    />
                  )}
                  rules={{ required: 'This field is required' }}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid container spacing={2} xs={12}>
            <Grid xs={6}>
              <Controller
                name={`${name}.startDate`}
                control={control}
                rules={{
                  required: 'This field is required',
                  validate: {
                    maxDate: date =>
                      dayjs(date).isSameOrBefore(dayjs(), 'month') ||
                      'Block dates should be in past',
                    beforeEnd: date =>
                      !watchEndDate ||
                      dayjs(watchEndDate).isSameOrAfter(dayjs(date), 'month') ||
                      'Incorrect date range',
                  },
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={!!field.value ? dayjs(field.value).toDate() : null}
                    calendarStartDay={1}
                    isClearable
                    customInput={
                      <DateTextInput
                        className="text-custom-input"
                        formatDate="YYYY-MM"
                        icon={<CalendarIcon />}
                        datatest="startDate"
                      />
                    }
                    showPopperArrow={false}
                    maxDate={watchEndDate || dayjs().toDate()}
                    placeholderText="Start date"
                    showMonthYearPicker
                    dateFormat="yyyy-MM"
                  />
                )}
              />
              {!!(fieldState.error as any)?.startDate && (
                <FormHelperText error>
                  {(fieldState.error as any)?.startDate?.message?.toString() || 'Incorrect data'}
                </FormHelperText>
              )}
            </Grid>
            <Grid xs={6}>
              <Controller
                control={control}
                defaultValue={item?.endDate}
                name={`${name}.endDate`}
                rules={{
                  validate: {
                    isRequired: date =>
                      watch(`${name}.isCurrentWork`) || !!date || 'This field is required',
                    maxDate: date =>
                      !date ||
                      dayjs(date).isSameOrBefore(dayjs(), 'month') ||
                      'Block dates should be in past',
                    afterStart: date =>
                      !date ||
                      !watchStartDate ||
                      dayjs(watchStartDate).isSameOrBefore(dayjs(date), 'month') ||
                      'Incorrect date range',
                  },
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={!!field.value ? dayjs(field.value).toDate() : null}
                    calendarStartDay={1}
                    isClearable
                    onChange={value =>
                      !!value ? field.onChange(dayjs(value).toISOString()) : null
                    }
                    customInput={
                      <DateTextInput
                        className="text-custom-input"
                        formatDate="YYYY-MM"
                        icon={<CalendarIcon />}
                        datatest="endDate"
                      />
                    }
                    showPopperArrow={false}
                    placeholderText="End date"
                    disabled={watch(`${name}.isCurrentWork`)}
                    maxDate={dayjs().toDate()}
                    minDate={watchStartDate || undefined}
                    showMonthYearPicker
                    dateFormat="yyyy-MM"
                  />
                )}
              />
              {!!(fieldState.error as any)?.endDate && (
                <FormHelperText error>
                  {(fieldState.error as any)?.endDate?.message?.toString() || 'Incorrect data'}
                </FormHelperText>
              )}
            </Grid>
            <Grid xs={6} md={12}>
              <FormGroup>
                <Controller
                  control={control}
                  defaultValue={!item?.endDate}
                  name={`${name}.isCurrentWork`}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          data-test="still-working-checkbox"
                          checked={field.value}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            field.onChange(event.target.checked);
                            if (event.target.checked) {
                              setValue(`${name}.endDate`, null);
                            }
                            trigger(`${name}.endDate`);
                          }}
                          size="small"
                          checkedIcon={<span />}
                          icon={<span />}
                        />
                      }
                      label="Still working here"
                      sx={{ marginLeft: 0, alignItems: 'flex-start' }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid xs={6} md>
              {mobile && (
                <FormGroup>
                  <Controller
                    control={control}
                    defaultValue={item?.isShowCompanyLogo}
                    name={`${name}.isShowCompanyLogo`}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-test="intro-checkbox"
                            {...field}
                            checked={field.value}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              field.onChange(event.target.checked);
                            }}
                            size="small"
                            checkedIcon={<span />}
                            icon={<span />}
                          />
                        }
                        label="Show in my Intro"
                        sx={{ marginLeft: 0, alignItems: 'flex-start' }}
                      />
                    )}
                  />
                </FormGroup>
              )}
            </Grid>
          </Grid>
        </Grid>
        <StyledFormGroup container xs={12} md="auto" order={{ xs: 2, md: 4 }}>
          <Grid xs="auto" display={{ md: 'none' }}>
            <StyledInputLabel>Company Logo</StyledInputLabel>
          </Grid>
          <Grid xs="auto" md={12} alignSelf="start" sx={{ position: 'relative' }}>
            <UploadPhoto
              // name={name}
              uploadDisabled={!watchCompany?.create}
              onUploadPhoto={handelUploadPhoto}
              icon={<CameraIcon />}
              size="large"
              image={watchCompany?.logo}
              round
            />
            {!disabledForAgency && getValues().experiences[0].experiences[0].company && (
              <StyledCloseButton onClick={onRemoveExperiences}>
                <CloseIcon />
              </StyledCloseButton>
            )}
            {!mobile && (
              <Box display="flex" justifyContent="end" sx={{ mt: 8 }}>
                <Button
                  data-test="save-button-desktop"
                  className="save-button"
                  onClick={onSaveExperiences}
                  color="secondary"
                  disabled={!formState.isValid}
                  // disabled={!isDirty || !formState.isValid}
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            )}
          </Grid>
        </StyledFormGroup>
        {mobile && (
          <Grid xs={12} order={4} textAlign="center">
            <Button
              data-test="save-button-mobile"
              className="save-button"
              onClick={onSaveExperiences}
              color="secondary"
              disabled={!formState.isValid}
              // disabled={!isDirty || !formState.isValid}
              variant="outlined"
              sx={{ my: 2 }}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider />
    </>
  );
};

export default ExperienceForm;
