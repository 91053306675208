import React, { FunctionComponent, useState } from 'react';
import debounce from 'lodash/debounce';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { outputQuery } from 'helpers/decorators';

import { useAppSelector, useAppDispatch } from 'store/hooks';

import { EOrderFilter, ESorting } from 'models/consts';
import { getPurchaseOrdersSelector } from 'store/selectors/getEnterpriseSelector';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import getPurchaseOrders from 'store/query/enterprise/getPurchaseOrders';

import { BootstrapInput } from 'components/CustomFields';
import tabOrder from 'components/Order/OrderList/tabOrder';
import ROUTES from 'router/constants';
import PlusIcon from 'styles/icons/PlusIcon';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { OrderListTab } from 'components/Order/OrderList';
import IconButton from '@mui/material/IconButton';
import SortDescIcon from 'styles/icons/SortDescIcon';
import PurchaseListPagination from './PurchaseListPagination';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

const PurchaseList: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(getMeRoleSelector);
  const purchaseOrders = useAppSelector(getPurchaseOrdersSelector);
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = React.useState<EOrderFilter>(EOrderFilter.PURCHASE_ORDERS);
  const [sorting, setSorting] = useState<ESorting>(ESorting.ASC);

  const handleChangeFilter = (event: any, newFilter: any) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const onSearch = (searchTerm: any) => {
    dispatch(getPurchaseOrders(searchTerm));
  };

  const debouncedSearch = debounce(onSearch, 500);

  const handleChange = (value: any, field: string) => {
    const filter = {
      offset: 0,
      limit: 10,
      [field]: value,
      key: `purchaseList${value ? ':' + value : ''}`,
    };

    debouncedSearch(outputQuery(filter));
  };

  const changeSorting = () => {
    setSorting(sorting => (sorting === ESorting.ASC ? ESorting.DESC : ESorting.ASC));
  };

  return (
    <>
      {!isPhone && (
        <Box pt={2} sx={{ display: { xs: 'flex', md: 'none' } }} justifyContent="flex-end">
          <Button
            id="create-an-order"
            component={Link}
            variant="contained"
            color="secondary"
            startIcon={<PlusIcon />}
            sx={{ height: 44, px: 2.5 }}
            to={ROUTES.ORDER_CREATION + '/1'}
          >
            Create an order
          </Button>
        </Box>
      )}
      {/* <Box mt={{ md: 4, sm: 3, xs: 2 }}> //Hiding thsi for now until it's working for POs & orders
        <BootstrapInput
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 2, mr: 0 }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event: any) => {
            handleChange(event.target.value, 'filterByName');
          }}
          placeholder="Search"
          sx={{ width: { md: 300 } }}
        />
      </Box> */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1.5}>
        <Tabs
          value={filter}
          variant="scrollable"
          onChange={handleChangeFilter}
          aria-label="Orders"
          textColor="secondary"
          indicatorColor="primary"
        >
          {tabOrder.map(
            tab =>
              tab.roles.includes(role.name) && (
                <Tab disableRipple key={tab.value} value={tab.value} label={tab.label} />
              ),
          )}
        </Tabs>
        {filter !== EOrderFilter.PURCHASE_ORDERS && (
          <IconButton
            onClick={changeSorting}
            size="small"
            sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
          >
            {sorting === ESorting.DESC ? (
              <SortDescIcon />
            ) : (
              <SortDescIcon sx={{ transform: 'rotateX(180deg)' }} />
            )}
          </IconButton>
        )}
      </Box>
      {filter !== EOrderFilter.PURCHASE_ORDERS && (
        <OrderListTab filter={filter} sortStartDate={sorting} />
      )}
      {filter === EOrderFilter.PURCHASE_ORDERS && <PurchaseListPagination />}
    </>
  );
};

export default PurchaseList;
