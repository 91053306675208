import React, { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'utils/dayjs';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';

import ROUTES from 'router/constants';
import { EOrderFilter, ERole } from 'models/consts';
import { IBasicOrders } from 'store/types/order';
import { IBasicPreOrder } from '../../../store/types/preOrder';

import { calcWeekCount } from 'utils/dateFormatter';
import { statusText } from 'utils/textFormatter';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getMeRoleSelector,
  isAgencyAdministratorsSelector,
  isConsultantSelector,
} from 'store/selectors/getUserSelector';
import { isOrderDraftCreationLoadingSelector } from 'store/selectors/getOrderDraftCreationSelector';
import { getAgencyUsersSelector } from 'store/selectors/getAgencySelector';
import updateOrder from 'store/query/agency/updateOrder';
import sendDraftNotification from 'store/query/order/sendDraftNotification';
import approveDraftOrder from 'store/query/order/approveDraftOrder';

import {
  BorderLinearProgress,
  StyledItemHeader,
  StyledColaborator,
  StyledItemWrap,
  StyledLink,
  StyledPaper,
  StyledTimelineWrap,
} from './styled';

import Avatar from 'components/Avatar';
import BootstrapInput from '../../CustomFields/BootstrapInput';
import Menu from './Menu';

export interface IOrderItemProps {
  order: IBasicOrders;
  filter: EOrderFilter;
  onOrderEdit?: (order: IBasicOrders | IBasicPreOrder) => void;
  onOrderAssign?: () => void;
}

const OrderItem: FunctionComponent<IOrderItemProps> = ({
  filter,
  order,
  onOrderEdit,
  onOrderAssign,
}) => {
  const dispatch = useAppDispatch();

  const isConsultant = useAppSelector(isConsultantSelector);
  const isAgencyAdministrator = useAppSelector(isAgencyAdministratorsSelector);
  const isFetching = useAppSelector(isOrderDraftCreationLoadingSelector);

  const users = useAppSelector(getAgencyUsersSelector);
  const role = useAppSelector(getMeRoleSelector);

  const filteredUsers = users.filter(item => !!item.firstName);

  const orderProgress = useMemo(() => {
    const totalWeek = calcWeekCount(order.timeLine?.startDate, order.timeLine?.endDate);
    const progress = calcWeekCount(order.timeLine?.startDate);
    const percent = progress > totalWeek ? 100 : (progress * 100) / totalWeek;

    return {
      totalWeek: Math.round(totalWeek),
      progressWeek: Math.round(progress > 0 ? progress : 0),
      progressPercent: percent,
    };
  }, [order?.timeLine]);

  const orderStatus = useMemo(() => {
    if (filter === EOrderFilter.DRAFT_ORDERS) {
      return 'drafts';
    }
    return order.status || '';
  }, [order.status, filter]);

  const orderLink = useMemo(() => {
    if (filter === EOrderFilter.DRAFT_ORDERS) {
      return `${ROUTES.DRAFT_ORDER}/${order.id}`;
    }
    if (filter === EOrderFilter.CURRENT && !!order.purchaseOrder) {
      return `${ROUTES.ORDER}/${order.id}/enterprise`;
    }
    return `${ROUTES.ORDER}/${order.id}`;
  }, [order.id, filter, order.purchaseOrder]);

  const handleSendDraftAgain = () => {
    dispatch(sendDraftNotification({ id: order.id }));
  };

  const handleApproveDraft = () => {
    dispatch(approveDraftOrder({ id: order.id }));
  };

  const onAssignConsultant = (event: SelectChangeEvent) => {
    dispatch(
      updateOrder({
        orderId: order.id,
        userId: event.target.value,
        key: order.customer.id,
      }),
    )
      .unwrap()
      .then(() => {
        onOrderAssign && onOrderAssign();
      });
  };

  const totalHours = order.timeLine?.actualHours || order.timeLine?.allHours;

  const getAvatar = useMemo(() => {
    if (isConsultant) {
      return order.company?.logo || order.customer?.customer
        ? order.customer?.customer?.companies && order.customer?.customer?.companies[0]?.logo
        : undefined;
    }
    return order.worker?.consultant?.avatarUrl || order.agency?.logo;
  }, [
    isConsultant,
    order.agency?.logo,
    order.company?.logo,
    order.customer?.customer,
    order.worker?.consultant?.avatarUrl,
  ]);

  const getName = useMemo(() => {
    if (isConsultant) {
      return (
        order.company?.name ||
        (order.customer?.customer ? (
          order.customer?.customer?.companies && order.customer?.customer?.companies[0]?.name
        ) : (
          <>
            [Pending invitation]
            <br />
            {order.customer?.email || order.customerEmail || ''}
          </>
        ))
      );
    }
    return (
      order.worker?.displayName ||
      (order.worker?.firstName && `${order.worker?.firstName} ${order.worker?.lastName}`) ||
      order.worker?.initials ||
      order.agency?.name
    );
  }, [
    isConsultant,
    order.agency?.name,
    order.company?.name,
    order.customer?.customer,
    order.customer?.email,
    order.customerEmail,
    order.worker,
  ]);

  return (
    <StyledPaper elevation={3}>
      <StyledItemWrap p={2}>
        <StyledItemHeader display="flex" alignItems="end">
          <Box display="flex" alignItems="center" flexGrow={1} justifyContent="space-between">
            <StyledLink to={orderLink} state={{ previousLocationPathname: location.pathname }}>
              {order?.name}
            </StyledLink>
            {([ERole.CUSTOMER_ADMIN, ERole.SELF_CUSTOMER, ERole.CUSTOMER].includes(role?.name) ||
              filter === EOrderFilter.DRAFT_ORDERS) && (
              <Menu
                filter={filter}
                handleApproveDraft={handleApproveDraft}
                onOrderEdit={onOrderEdit}
                order={order}
              />
            )}
          </Box>
        </StyledItemHeader>
        <Divider sx={{ my: 1 }} />
        <Box
          display={{ xs: 'flex', md: 'block' }}
          alignItems="center"
          justifyContent="space-between"
        >
          {order?.hashCode && (
            <Typography color="text.secondary" gutterBottom>
              Order{' '}
              <Typography color="text.primary" component="span">
                #{order?.hashCode}
              </Typography>
            </Typography>
          )}
          <Chip
            sx={{ mr: 1.5, mb: 2 }}
            size="small"
            label={statusText(orderStatus)}
            className={orderStatus}
          />
        </Box>
        <Box sx={{ height: 34, mb: 2 }}>
          {(orderStatus === 'waiting_for_payment' ||
            orderStatus === 'created' ||
            orderStatus === 'active' ||
            orderStatus === 'ready_to_start') && (
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography color="text.secondary" gutterBottom>
                  Progress
                </Typography>
                <Typography color="text.primary">{`${orderProgress?.progressWeek}/${orderProgress?.totalWeek} weeks`}</Typography>
              </Box>
              <BorderLinearProgress
                className={order.status}
                variant="determinate"
                value={orderProgress?.progressPercent}
              />
            </>
          )}
        </Box>
        <StyledTimelineWrap mb={2} px={1.5} py={2.5}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography component="span">
              <strong>Total hours</strong>
            </Typography>
            <Typography component="span">{totalHours} hours</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography component="span">
              <strong>Timeline</strong>
            </Typography>
            <Typography component="span">
              {dayjs.utc(order.timeLine?.startDate).format('DD MMM, YYYY')} -{' '}
              {dayjs.utc(order.timeLine?.endDate).format('DD MMM, YYYY')}
            </Typography>
          </Box>
        </StyledTimelineWrap>
        <Box display="flex" alignItems="center" mb={2}>
          {isConsultant ? (
            <>
              <Avatar
                image={getAvatar}
                firstName={order.customer?.firstName || order.customer.initials}
                lastName={order.customer?.lastName || undefined}
              />
              <StyledColaborator ml={1.5}>{getName}</StyledColaborator>
            </>
          ) : (
            (isAgencyAdministrator && !order.worker?.id && (
              <MuiSelect
                displayEmpty
                input={<BootstrapInput />}
                onChange={onAssignConsultant}
                defaultValue="Select a specialist"
              >
                <MenuItem disabled value="Select a specialist" selected>
                  Select a specialist
                </MenuItem>
                {filteredUsers.map((item: any) => (
                  <MenuItem key={item.email} value={item.id}>
                    {`${item.firstName} ${item.lastName}`}
                  </MenuItem>
                ))}
              </MuiSelect>
            )) || (
              <>
                <Avatar
                  image={getAvatar}
                  firstName={
                    order.worker?.firstName || order.worker?.initials || order.agency?.name || ''
                  }
                  lastName={
                    order.worker?.lastName ||
                    (order.agency?.name && order.worker?.initials
                      ? undefined
                      : order.worker?.initials
                        ? undefined
                        : '')
                  }
                />
                <StyledColaborator ml={1.5}>{getName}</StyledColaborator>
              </>
            )
          )}
        </Box>
        <Box display="flex" justifyContent="center" px={2}>
          {filter === EOrderFilter.DRAFT_ORDERS && isConsultant && (
            <Button color="secondary" fullWidth variant="contained" onClick={handleSendDraftAgain}>
              Send Draft Again
            </Button>
          )}
          {filter === EOrderFilter.DRAFT_ORDERS && !isConsultant && (
            <LoadingButton
              color="secondary"
              fullWidth
              variant="contained"
              onClick={handleApproveDraft}
              disabled={isFetching}
              loading={isFetching}
            >
              Approve
            </LoadingButton>
          )}
          {filter !== EOrderFilter.DRAFT_ORDERS && (
            <Button
              color="secondary"
              component={Link}
              to={`${ROUTES.CHAT}/${order.chat?.id}`}
              fullWidth
              variant="contained"
            >
              {([ERole.CUSTOMER_ADMIN, ERole.SELF_CUSTOMER, ERole.CUSTOMER].includes(
                role?.name,
              ) && <>Contact your Workerbee</>) || <>Contact Customer</>}
            </Button>
          )}
        </Box>
      </StyledItemWrap>
    </StyledPaper>
  );
};

export default OrderItem;
